import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/login/login';
import { loginApi, setCookie } from './utils/utils';
import Home from './components/home/home';
import Chat from './components/chat';
import { openSideBar } from './store/sidebarSlice';
import { useDispatch } from 'react-redux';

const AppRoutes: React.FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const SESSION_TIMEOUT = 60 * 60 * 1000;

  
  const login = async (username: string, password: string) => {
    if (username !== "" && password !== "") {
      const response = await loginApi(username, password);
      const currentTime = new Date().getTime();
      setIsAuthenticated(true)
      setCookie(response?.data.accessToken)
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('idToken', response.data.idToken);
      localStorage.setItem('username', response.data.user.username);
      localStorage.setItem('authTimestamp', currentTime.toString());
    } else {
      alert('username or password should not empty');
    }
  };


  useEffect(() => {
    const isAuth = localStorage.getItem('isAuthenticated') || isAuthenticated;
    const authTimestamp = localStorage.getItem('authTimestamp');
    const currentTime = new Date().getTime();

    if (isAuth === 'true' && authTimestamp && currentTime - parseInt(authTimestamp) < SESSION_TIMEOUT) {
      setIsAuthenticated(true);
      dispatch(openSideBar());
      navigate('/');
    } else {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('authTimestamp');
      setIsAuthenticated(false);
      navigate('/login');
    }
  }, [ isAuthenticated ]);



  if( !isAuthenticated ) { 
    return <Login login={login} />
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login login={login} />} />
        <Route path="/about" element={<>Hello About us</>} />
        <Route path="/contact" element={<>Hello contact us</>} />
        <Route path="/asksandra" element={<Chat />} />
    </Routes>
  );
};

export default AppRoutes;
