import React, { useRef, useEffect, useState } from 'react';
import ChatHeader from './ChatHeader';
import MessageInput from './MessageInput';
import DynamicResponseRenderer from './DynamicResponseRenderer';
import { ChatProvider, useChatContext } from '../../context/ChatContext';
import ComponentRegistry from './ComponentRegistry';

const ChatInterface: React.FC = () => {
    const { displayedMessages, sendMessageWithResponse, shouldComponentRender, componentName, componentMessage, isChatLoading } = useChatContext();
    const [currentText, setCurrentText] = useState<string>('');
    const chatEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 0);

        return () => clearTimeout(timeout);
    }, [displayedMessages]);


    const handleUserMessage = (message: string) => {
        sendMessageWithResponse(message);
        setCurrentText('');
    };

    const renderComponent = componentName && shouldComponentRender && componentMessage;
    const Component = renderComponent ? ComponentRegistry[componentName] : null;

    return (
        <div className="flex flex-col h-screen relative pr-2">
            <ChatHeader />
            <div className="flex-1 overflow-y-auto p-4 pb-[80px] space-y-5 bg-gray-10">
                {displayedMessages.map((msg: any, index: { toString: () => React.Key | null | undefined; }) => (
                    <DynamicResponseRenderer key={index.toString()} message={msg} />
                ))}
                {
                    isChatLoading && (<DynamicResponseRenderer message={null} isChatLoading />)
                }
                <div ref={chatEndRef} />
                {(renderComponent && Component ) ? <Component message={componentMessage} /> : null}
            </div>
            <MessageInput text={currentText} setText={setCurrentText} onSendMessage={handleUserMessage} />
        </div>
    );
};

const ChatInterfaceWithProvider: React.FC = () => (
    <ChatProvider>
        <ChatInterface />
    </ChatProvider>
);

export default ChatInterfaceWithProvider;
