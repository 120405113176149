import React from 'react';

const ChatHeader: React.FC = () => (
    <div className='bg-white'>
        <div className='bg-teal-gradient rounded-b-lg w-full h-[10px] rounded-full mt-5'></div>
        <div className="flex items-center justify-between px-4 py-3 bg-gray-10 pt-5">
            <div className="flex items-center space-x-2">
                {/* Back Icon */}
                <span className="text-gray-600 cursor-pointer ml-[32px]">←</span>

                {/* Title with Icon */}
                <div className="flex items-center space-x-1">
                    <span className="text-lg font-bold flex items-center">
                        <span className="text-teal-600 mx-5">✦</span> Ask Sandra
                    </span>
                    <span className="text-sm bg-[#08B4BD1A] text-gray-600 px-2 py-1 rounded-full mx-5">General</span>
                </div>
            </div>
        </div>
    </div>
);

export default ChatHeader;
