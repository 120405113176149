import React, { createContext, useContext, useState, ReactNode } from 'react';

type Message = {
    id: string;
    type: string;
    content: string;
    sender: string;
};

type ChatContextType = {    
    displayedMessages: Message[];
    setNewMessage: (message: Message) => void;
    componentMessage: Message | null;
    componentName: string | null;
    setComponentName: (name: string | null) => void;
    shouldComponentRender: boolean;
    setShouldComponentRender: (value: boolean) => void;
    sendMessageWithResponse: (message: string) => void;
    componentTriggerMessageId: string | null;
    setComponentMessage: (message : Message | null) => void;
    isChatLoading: boolean;
};

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChatContext = () => {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }
    return context;
};

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [displayedMessages, setDisplayedMessages] = useState<Message[]>([{ id: Date.now().toString(), type: 'text', content: "Hello! I'm Sandra, your AI assistant for medical billing and insurance queries. How can I help you today?", sender: 'assistant' }]);
    const [componentName, setComponentName] = useState<string | null>(null);
    const [componentMessage, setComponentMessage] = useState<Message | null>(null);
    const [shouldComponentRender, setShouldComponentRender] = useState<boolean>(false);
    const [componentTriggerMessageId, setComponentTriggerMessageId] = useState<string | null>(null);
    const [isChatLoading, setIsChatLoading] = useState<boolean>(false);

    const setNewMessage = (message: Message) => {
        setDisplayedMessages((prev) => [...prev, message]);
    };

    const sendMessageWithResponse = async (message: string) => {
        try {
            setIsChatLoading(true);
            // Add the user's message
            setNewMessage({
                type: 'text', content: message, sender: 'user',
                id: `${Date.now()}`
            });
  
            const myHeaders = new Headers()
            const username : any = await localStorage.getItem('username');
            const token : any = await localStorage.getItem('idToken');
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "input": `${message}`,
                "username": `${username}`,
                "flag_new_conversation": "no"
            });
            
            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch("https://w686ou7pke.execute-api.us-east-1.amazonaws.com/stage/agenticSandra", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                const responseMessage = { type: 'text', content: result?.response, sender: 'assistant' };
                setIsChatLoading(false);
                //@ts-ignore
                setNewMessage(responseMessage);
                })
                .catch((error) => {
                console.error(error);
                setIsChatLoading(false);
            });
        } catch (error) {
            console.error("Error: " + error);
            setIsChatLoading(false);
        }
    };

    return (
        <ChatContext.Provider
            value={{
                displayedMessages,
                setNewMessage,
                componentName,
                setComponentName,
                shouldComponentRender,
                setShouldComponentRender,
                sendMessageWithResponse,
                componentTriggerMessageId,
                componentMessage,
                setComponentMessage,
                isChatLoading
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
