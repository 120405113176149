import React from 'react';
import { svgNameMap } from '../../assets/SVGs';

interface IProps {
  iconName: string;
  iconColor?: string;
  className?: string;
  textClass?: string;
  notificationNumber?: string;
  text: string;
  clickHandler?: () => void;
}

const IconWithText: React.FC<IProps> = ({
  iconName,
  iconColor,
  className,
  textClass,
  notificationNumber,
  clickHandler,
  text,
}) => {
  const IconComponent = iconName ? svgNameMap[iconName] : null;

  return (
    <div
      className={`w-full flex items-center cursor-pointer rounded-lg ${className || ''}`}
      onClick={clickHandler}
    >
      <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full">
        {IconComponent && (
          <IconComponent color={iconColor ? iconColor : '#818BA0'} />
        )}
      </div>
      <div className={`ml-3 md:ml-2 text-[16px] md:text-[14px] ${textClass || ''}`}>
        {text}
      </div>
      {notificationNumber && (
        <div className="ml-auto bg-[#F3B949] text-[#242E49] rounded-full w-5 h-5 md:w-6 md:h-6 flex items-center justify-center text-xs md:text-sm font-extrabold">
          {notificationNumber}
        </div>
      )}
    </div>
  );
};

export default IconWithText;
