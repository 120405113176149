import * as React from 'react';
import { SVG } from '../../utils/types';

const YellowDollor: React.FC<SVG> = ({
	width = 36,
	height = 37,
	color = '#FFAB00',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path
			d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM20.115 27.135V28.005C20.115 29.1 19.215 30 18.12 30H18.105C17.01 30 16.11 29.1 16.11 28.005V27.105C14.115 26.685 12.345 25.59 11.595 23.745C11.25 22.92 11.895 22.005 12.795 22.005H13.155C13.71 22.005 14.16 22.38 14.37 22.905C14.805 24.03 15.945 24.81 18.135 24.81C21.075 24.81 21.735 23.34 21.735 22.425C21.735 21.18 21.075 20.01 17.73 19.215C14.01 18.315 11.46 16.785 11.46 13.71C11.46 11.13 13.545 9.45 16.125 8.895V7.995C16.125 6.9 17.025 6 18.12 6H18.135C19.23 6 20.13 6.9 20.13 7.995V8.925C22.2 9.435 23.505 10.725 24.075 12.315C24.375 13.14 23.745 14.01 22.86 14.01H22.47C21.915 14.01 21.465 13.62 21.315 13.08C20.97 11.94 20.025 11.205 18.135 11.205C15.885 11.205 14.535 12.225 14.535 13.665C14.535 14.925 15.51 15.75 18.54 16.53C21.57 17.31 24.81 18.615 24.81 22.395C24.78 25.14 22.725 26.64 20.115 27.135Z"
			fill="url(#paint0_linear_1_1205)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_1205"
				x1={3}
				y1={3}
				x2={33}
				y2={33}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={color} />
				<stop offset={1} stopColor="#B76E00" />
			</linearGradient>
		</defs>
	</svg>
);
export default YellowDollor;
