import ChatBubble from './ChatBubble';
import MultiSelect from './MultiSelect';
import Test1 from './Test1';
import Test2 from './Test2';

const ComponentRegistry: { [key: string]: React.ComponentType<any> } = {
    ChatBubble,
    Test1,
    Test2,
    MultiSelect
};

export default ComponentRegistry;
