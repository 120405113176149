import React from 'react';
import ChatBubble from './ChatBubble';
import ComponentRegistry from './ComponentRegistry';
import { useChatContext } from '../../context/ChatContext';
import ChatLoading from './ChatLoading';

type Message = {
    type: 'text' | 'button';
    content: string;
    sender: 'user' | 'assistant';
};

type MessageDisplayProps = {
    message: Message | null;
    isChatLoading?: boolean;
};

// Regular expression to detect HTML tags
const isHtml = (content: string) => /<\/?[a-z][\s\S]*>/i.test(content);

const DynamicResponseRenderer: React.FC<MessageDisplayProps> = ({ message, isChatLoading }) => {
    const { shouldComponentRender, componentName } = useChatContext();

    if (isChatLoading) {
        return <ChatLoading />;
    }
    if (!message || !message.content) {
        console.warn('DynamicResponseRenderer received an invalid message:', message);
        return null;
    }

    // HTML rendering
    if (isHtml(message.content)) {
        return (
            <div
                className={`px-4 py-2 rounded-lg max-w-xs ${
                    message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-800'
                }`}
                dangerouslySetInnerHTML={{ __html: message.content }}
            />
        );
    }

    // Default to ChatBubble for plain text or unrecognized types
    return (
        <ChatBubble
            text={message.content}
            sender={message.sender}
            type={message.type}
        />
    );
};

export default DynamicResponseRenderer;
