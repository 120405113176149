import * as React from 'react';
import { SVG } from '../../utils/types';

const MagicStar: React.FC<SVG> = ({
	width = 31,
	height = 29,
	color = '#9EA7B8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path
			d="M22.6666 0.110657L24.4266 3.40799L27.7226 5.16666L24.4266 6.92532L22.6666 10.2227L20.9093 6.92532L17.612 5.16532L20.9093 3.40799L22.6666 0.110657ZM10.6666 4.27732L14.2226 10.944L20.8893 14.5L14.2226 18.056L10.668 24.7227L7.11198 18.056L0.445312 14.5L7.11198 10.944L10.6666 4.27732ZM26.2226 20.2773L24.0013 16.1107L21.7786 20.2773L17.612 22.5L21.7786 24.7227L24.0013 28.8893L26.2226 24.7227L30.3893 22.5L26.2226 20.2773Z"
			fill={color}
		/>
	</svg>
);
export default MagicStar;
