import * as React from 'react';
import { SVG } from '../../utils/types';

const Document: React.FC<SVG> = ({
	width = 32,
	height = 32,
	color = '#9EA7B8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path d="M10.6666 11.1667H21.3333" stroke={color} strokeWidth="2.67" />
		<path d="M10.6666 16.5H21.3333" stroke={color} strokeWidth="2.67" />
		<path d="M10.6666 21.8333H16" stroke={color} strokeWidth="2.67" />
		<rect
			x="6.66663"
			y="4.5"
			width="18.6667"
			height="24"
			rx="3"
			stroke={color}
			strokeWidth="2.67"
		/>
	</svg>
);
export default Document;
