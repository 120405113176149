import React, { useState, useEffect } from 'react';
import SandraChatLogo from '../../assets/SandraChatLogo';

type ChatBubbleProps = {
    text: string;
    sender: 'user' | 'assistant';
    type: 'text' | 'button';
};

const ChatBubble: React.FC<ChatBubbleProps> = ({ text, sender, type }) => {
    const isUser = sender === 'user';
    const [displayedText, setDisplayedText] = useState(''); // Start with an empty string
    const typingSpeed = 25; // Speed of typing effect in ms

    useEffect(() => {
        if (isUser) {
            // If the message is from the user, display it instantly
            setDisplayedText(text);
            return;
        }

        // Typing effect for assistant messages
        setDisplayedText(''); // Reset displayed text for each new message

        const typeCharacter = (index: number) => {
            if (index <= text.length) {
                setDisplayedText(text.slice(0, index)); // Take a slice up to the current index
                setTimeout(() => typeCharacter(index + 1), typingSpeed);
            }
        };

        typeCharacter(1); // Start the typing effect

    }, [text, isUser]);

    if(isUser) {
      return(<div className={`flex justify-end`}>
            <div
                className={`px-4 py-2 rounded-[16px] max-w-[90%] bg-gray-70 text-white`}
            >
                {displayedText}
            </div>
          </div>)
    }

    return (
        <div className={`flex justify-start`}>
            <>
                <SandraChatLogo />
                <div
                    className={`px-4 py-2 rounded-[16px] max-w-[90%] bg-white text-[#5D6A85] ml-3`}
                >
                    {displayedText}
                </div>
                </>
        </div>
    );
};

export default ChatBubble;
