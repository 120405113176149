import * as React from 'react';
import { SVG } from '../../utils/types';

const Home: React.FC<SVG> = ({
	width = 28,
	height = 28,
	color = '#9EA7B8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path
			d="M4 11L14 3L24 11V23C24 24.1046 23.1046 25 22 25H6C4.89543 25 4 24.1046 4 23V11Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 25V14H18V25"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Home;
