import axios from 'axios';
import { DOMAIN } from './constants';

export const getCookie = (name: string) => {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
        let cookie = cookieArr[i].trim();
        if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
        }
    }
    return null;
}

// setCookie.js
export const setCookie = (value: string, name = "Authorized", days = 1, secure = false, sameSite = 'Lax') => {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
    const date = new Date();
    date.setDate(date.getDate() + days);
    cookieString += `expires=${date.toUTCString()};`;
    if (secure) {
        cookieString += 'Secure;';
    }
    cookieString += `SameSite=${sameSite};`;
    cookieString += 'path=/;';
    document.cookie = cookieString;
};
  
export const loginApi = async (username: string, password: string) => {
    try {
        const response = await axios.post(
        DOMAIN + '/auth/login',
        {
            username: username,
            password: password
        }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching total collection:', error);
        throw error;
    }
};