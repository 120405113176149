import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Sidebar from './components/sidebar/Sidebar';
import AppRoutes from './router';

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<div className="App flex">
					<Sidebar />
					<div className="w-full bg-[#F2F5F9]">
						<AppRoutes />
					</div>
				</div>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
