import * as React from 'react';
import { SVG } from '../../utils/types';

const Bulb: React.FC<SVG> = ({
	width = 32,
	height = 33,
	color = '#9EA7B8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width}px ${height}px`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<mask id="path-1-inside-1_1_1167" fill="white">
			<path d="M12 28.5C12 29.2334 12.6 29.8334 13.3333 29.8334H18.6666C19.4 29.8334 20 29.2334 20 28.5V27.1667H12V28.5ZM16 3.16669C10.8533 3.16669 6.66663 7.35335 6.66663 12.5C6.66663 15.6734 8.25329 18.46 10.6666 20.1534V23.1667C10.6666 23.9 11.2666 24.5 12 24.5H20C20.7333 24.5 21.3333 23.9 21.3333 23.1667V20.1534C23.7466 18.46 25.3333 15.6734 25.3333 12.5C25.3333 7.35335 21.1466 3.16669 16 3.16669ZM19.8 17.9667L18.6666 18.7667V21.8334H13.3333V18.7667L12.2 17.9667C10.4 16.7134 9.33329 14.6734 9.33329 12.5C9.33329 8.82002 12.32 5.83335 16 5.83335C19.68 5.83335 22.6666 8.82002 22.6666 12.5C22.6666 14.6734 21.6 16.7134 19.8 17.9667Z" />
		</mask>
		<path
			d="M12 28.5C12 29.2334 12.6 29.8334 13.3333 29.8334H18.6666C19.4 29.8334 20 29.2334 20 28.5V27.1667H12V28.5ZM16 3.16669C10.8533 3.16669 6.66663 7.35335 6.66663 12.5C6.66663 15.6734 8.25329 18.46 10.6666 20.1534V23.1667C10.6666 23.9 11.2666 24.5 12 24.5H20C20.7333 24.5 21.3333 23.9 21.3333 23.1667V20.1534C23.7466 18.46 25.3333 15.6734 25.3333 12.5C25.3333 7.35335 21.1466 3.16669 16 3.16669ZM19.8 17.9667L18.6666 18.7667V21.8334H13.3333V18.7667L12.2 17.9667C10.4 16.7134 9.33329 14.6734 9.33329 12.5C9.33329 8.82002 12.32 5.83335 16 5.83335C19.68 5.83335 22.6666 8.82002 22.6666 12.5C22.6666 14.6734 21.6 16.7134 19.8 17.9667Z"
			fill="black"
			stroke={color}
			strokeWidth={5.34}
			mask="url(#path-1-inside-1_1_1167)"
		/>
	</svg>
);
export default Bulb;
