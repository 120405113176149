import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
  isOpen: boolean;
  activeSidebarIconId: number;
}

const initialState: SidebarState = {
  isOpen: false,
  activeSidebarIconId: 1,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
    openSideBar: (state) => {
      state.isOpen = true;
    },
    setActiveSidebarIconId: (state, action: PayloadAction<number>) => {
      state.activeSidebarIconId = action.payload;
    }
  },
});

export const { toggleSidebar, openSideBar, setActiveSidebarIconId } = sidebarSlice.actions;
export default sidebarSlice.reducer;
