import * as React from 'react';
import { SVG } from '../../utils/types';

const SignOut: React.FC<SVG> = ({
	width = 32,
	height = 33,
	color = '#9EA7B8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path d="M10.6666 16.5L28 16.5" stroke={color} strokeWidth="2" />
		<path
			d="M22.6667 11.1667L26.9393 15.4393C27.5251 16.0251 27.5251 16.9749 26.9393 17.5607L22.6667 21.8333"
			stroke={color}
			strokeWidth="2"
		/>
		<path
			d="M16 4.5L8.33334 4.5C6.67648 4.5 5.33333 5.84315 5.33333 7.5L5.33333 25.5C5.33333 27.1569 6.67648 28.5 8.33333 28.5L16 28.5"
			stroke={color}
			strokeWidth="2"
		/>
	</svg>
);
export default SignOut;
