import * as React from 'react';
import { SVG } from '../../utils/types';

const SidebarLogo: React.FC<SVG> = ({
	width = 41,
	height = 36,
	color = '#FFFFFF',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<g clipPath="url(#clip0_1_1155)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.3161 0C31.5363 0 40.6321 9.09581 40.6321 20.3161C40.6321 22.5361 40.276 24.6729 39.6179 26.6726C38.3237 25.8537 36.58 25.6793 35.3563 26.5361L23.3591 34.9367C22.086 35.8281 20.514 36.1811 18.9861 35.9117L12.8048 35.0895C11.748 34.9031 10.9764 33.9925 10.9471 32.9305C10.9221 31.5082 12.1907 30.419 13.5861 30.6585L19.5959 31.7182C21.1875 31.9988 22.7002 30.9396 22.9809 29.348C23.1679 28.287 19.2991 26.6136 11.3743 24.3279C8.7004 23.8564 5.94731 24.4672 3.72311 26.0246L1.37209 27.6707C0.486071 25.3901 0 22.9098 0 20.3161C0 9.09581 9.09581 0 20.3161 0ZM28.6592 11.377H25.9504C25.2055 11.377 24.596 11.9865 24.596 12.7314V16.7946H20.5328C19.7878 16.7946 19.1784 17.4041 19.1784 18.149V20.8578C19.1784 21.6027 19.7878 22.2122 20.5328 22.2122H24.596V26.2754C24.596 27.0204 25.2055 27.6298 25.9504 27.6298H28.6592C29.4042 27.6298 30.0136 27.0201 30.0136 26.2754V22.2122H34.0768C34.8218 22.2122 35.4312 21.6025 35.4312 20.8578V18.149C35.4312 17.4041 34.8217 16.7946 34.0768 16.7946H30.0136V12.7314C30.0136 11.9865 29.4041 11.377 28.6592 11.377Z"
				fill="#08B4BD"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_1155">
				<rect width={40.6321} height={36} fill={color} />
			</clipPath>
		</defs>
	</svg>
);
export default SidebarLogo;
