import { ISidebarData } from '../utils/types';

export const sidebarBodyIcons: Array<ISidebarData> = [
	{
		id: 1,
		iconName: 'Home',
		title: 'Home',
		notification: '',
		path: '/',
	},
	{
		id: 2,
		iconName: 'Sandra',
		title: 'Ask Sandra',
		notification: '',
		path: '/asksandra',
	},
	{
		id: 3,
		iconName: 'Bill',
		title: 'Bills',
		notification: '',
		path: '/bills',
	},
	{
		id: 4,
		iconName: 'Dollar',
		title: 'Offers',
		notification: '',
		path: '/offers',
	},
	{
		id: 5,
		iconName: 'Wallet',
		title: 'Wallet',
		notification: '',
		path: '/wallet',
	},
	{
		id: 6,
		iconName: 'Insurance',
		title: 'Insurance',
		notification: '',
		path: '/insurance',
	},
	{
		id: 7,
		iconName: 'Gear',
		title: 'Setting',
		notification: '',
		path: '/setting',
	},
];

export const sidebarFootericons = [
	{
		id: 8,
		iconName: 'SignOut',
		title: 'Log Out',
		notification: '',
		path: '',
	},
];
