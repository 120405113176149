
import * as React from 'react';
import { SVG } from '../../utils/types';

const Spot : React.FC<SVG> = ({
	width = 32,
	height = 32,
	color = '#DCE1E8',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<rect y="0.5" width="32" height="32" rx="16" fill={color} />
	</svg>
);
export default Spot;
