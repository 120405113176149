import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import { RootState } from '../../store';
import { setActiveSidebarIconId, toggleSidebar } from '../../store/sidebarSlice';
import { sidebarBodyIcons, sidebarFootericons } from '../../constants/data';
import IconWithText from './IconWithText';

const Sidebar: React.FC = () => {
	const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
	const navigate = useNavigate();
	const location = useLocation();
	const activeSidebarIconId = useSelector(
		(state: RootState) => state.sidebar.activeSidebarIconId
	);
	const dispatch = useDispatch();

	const pathToIconIdMap: { [key: string]: number } = {
		'/': 1,
		'/chat': 2,
	};

	const handleScreenResize = () => {
		const isTablet = window.innerWidth <= 1024;
		if (isTablet && isOpen) {
			dispatch(toggleSidebar());
		} else if(!isOpen && window.innerWidth > 1024) {
			dispatch(toggleSidebar());
		}
	};

	useEffect(() => {
		handleScreenResize();
		window.addEventListener('resize', handleScreenResize);

		return () => {
			window.removeEventListener('resize', handleScreenResize);
		};
	}, [isOpen, dispatch]);

	useEffect(() => {
		const currentIconId = pathToIconIdMap[location.pathname];
		if (currentIconId && currentIconId !== activeSidebarIconId) {
			dispatch(setActiveSidebarIconId(currentIconId));
		}
	}, [location.pathname, activeSidebarIconId, dispatch]);

	if (!isOpen) {
		return <></>;
	}

	const onIconClickHandler = (icon: any) => {
		dispatch(setActiveSidebarIconId(icon.id));

		if (icon.path) {
			navigate(icon.path);
		}
	};

	return (
		<div
			className={`bg-white h-full w-[243px] md:w-[223px] bg-gray-800 transition-width duration-300`}
		>
			<div className="mt-5 flex justify-center item-center">
				<SidebarLogo />
			</div>
			<div className="mt-10 flex flex-col items-center">
				{/* <img
					src="src/assets/images/sidebarWomen.jpeg"
					className="w-16 h-16 mb-6 rounded-xl cursor-pointer border-primary border-2"
				/> */}
				<div className='flex flex-col justify-start items-start'>
					{sidebarBodyIcons.map(icon => (
						<IconWithText
							key={icon.title}
							clickHandler={() => onIconClickHandler(icon)}
							iconName={icon.iconName}
							text={icon.title.toLocaleUpperCase()}
							iconColor={activeSidebarIconId === icon.id ? '#08B4BD' : ''}
							className={`mb-6 rounded-xl p-[10px] cursor-pointer ${
								activeSidebarIconId === icon.id ? 'bg-[#DCE1E8]' : ''
							}`}
							textClass={
								activeSidebarIconId === icon.id
									? 'text-[#242E49] font-medium'
									: ''
							}
							notificationNumber={icon.notification}
						/>
					))}
				</div>
			</div>
			<div className="mt-10 flex flex-col items-center">
				{/* {sidebarFootericons.map(icon => (
					<IconWithText
						key={icon.title}
						clickHandler={() => onIconClickHandler(icon.id)}
						iconName={icon.iconName}
						text={icon.title.toLocaleUpperCase()}
						iconColor={activeSidebarIconId === icon.id ? '#08B4BD' : ''}
						className={`w-16 h-16 mb-6 rounded-xl p-[10px] cursor-pointer ${
							activeSidebarIconId === icon.id ? 'bg-[#DCE1E8]' : ''
						}`}
						textClass={
							activeSidebarIconId === icon.id
								? 'text-[#242E49] font-medium'
								: ''
						}
					/>
				))} */}
			</div>
		</div>
	);
};

export default Sidebar;
