import React, { useState } from 'react';

const Login: React.FC<{ login: (username: string, password: string) => void }> = ({ login }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    login(username, password);
  };

  return (
    <div className="min-h-screen flex flex-col">

      {/* Main Content */}
      <div className="flex flex-1">
        {/* Left Side: Sign-in Form */}
        <div className="flex flex-col justify-center w-full md:w-[55%] bg-[#F2F5F9] p-6 lg:p-12">
          <div className="sm:px-[32px] md:px-[64px]">
            {/* <div className='flex justify-center items-center'>
              <SidebarLogo />
            </div> */}
            <h2 className="mt-10 text-left text-2xl font-semibold leading-9 text-[#29406B]">
              Sign in
            </h2>
          </div>

          <div className="mt-10 sm:px-[32px] md:px-[64px]">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="username" className="font-semibold text-base text-[#29406B]">
                  Username
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="font-semibold text-base text-[#29406B]">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full bg-[white] text-[#29406B] border-1 font-medium px-4 py-3 rounded-[12px] ring-1 ring-inset ring-[#DCE1E8]"
                  />
                </div>
                <div className='w-full text-right mt-2'>
                  <label className='w-full text-sm text-right font-medium text-[#E24E40]'>Forgot password</label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex px-4 py-3 w-full justify-center rounded-md bg-teal-40 px-3 py-1.5 text-xl font-semibold text-[#3BA3AB] shadow-sm"
                >
                  Sign in
                </button>
              </div>

              <p className="mt-10 text-left text-lg text-[#3BA3AB] underline font-semibold">
              Create an Account
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
