
import * as React from 'react';
import { SVG } from '../../utils/types';

const QAColored : React.FC<SVG> = ({
	width = 37,
	height = 36,
	// color = '#FFFFFF',
	otherProps,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...otherProps}
	>
		<path
      d="M30.25 9H28.75V21C28.75 21.825 28.075 22.5 27.25 22.5H9.25V24C9.25 25.65 10.6 27 12.25 27H27.25L33.25 33V12C33.25 10.35 31.9 9 30.25 9ZM25.75 16.5V6C25.75 4.35 24.4 3 22.75 3H6.25C4.6 3 3.25 4.35 3.25 6V25.5L9.25 19.5H22.75C24.4 19.5 25.75 18.15 25.75 16.5Z"
      fill="url(#paint0_linear_1_1236)"
    />
    <path
      d="M30.25 9H28.75V21C28.75 21.825 28.075 22.5 27.25 22.5H9.25V24C9.25 25.65 10.6 27 12.25 27H27.25L33.25 33V12C33.25 10.35 31.9 9 30.25 9ZM25.75 16.5V6C25.75 4.35 24.4 3 22.75 3H6.25C4.6 3 3.25 4.35 3.25 6V25.5L9.25 19.5H22.75C24.4 19.5 25.75 18.15 25.75 16.5Z"
      fill="url(#paint1_linear_1_1236)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_1236"
        x1={3.25}
        y1={3}
        x2={33.25}
        y2={33}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35C480" />
        <stop offset={1} stopColor="#1F9A5F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_1236"
        x1={3.25}
        y1={3}
        x2={33.25}
        y2={33}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#118DAF" />
        <stop offset={1} stopColor="#044E6B" />
      </linearGradient>
    </defs>
	</svg>
);
export default QAColored;
